import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, Ref, watch } from '@vue/composition-api';

//import jsEncrypt from 'jsencrypt';
//import sha1 from './sha1-0.1.js';

import sha1 from 'sha1.js';

import Card from '@/components/Card';

import { Btn, Inp } from '@/components/UI';
import { req } from '@/api';

interface RegisterFormData {
	title: string;
	url: string;
	avatar: string;
	description: string;
	[propName: string]: string;
}

let timer: undefined | number = undefined;

export default defineComponent({
	directives: {
		codecopy(el) {
			el.querySelectorAll('code').forEach(element => {
				element.style.cursor = 'pointer';
				element.setAttribute('title', '点击复制');
				const fn = function (event: Event) {
					try {
						(window as any).getSelection().selectAllChildren(element);
						(document as any).execCommand('Copy');
					} catch (e) {
						//
					}
				};
				element.addEventListener('click', fn);
			});
		},
	},
	components: { Card, Btn, Inp },
	setup(props, { root }: any) {
		const { $message } = root;
		const t = root.$t.bind(root);
		const noticeTextZh = `#### 申请须知

- 本站拒绝添加违法违规站点，禁止一切产品营销、广告联盟等类型的网站

- 本站只接受博客类站点，流畅访问、稳定更新，并且拥有 SSL 安全证书

- 申请友情链接前您需要在您的网站添加本站链接

- 请在下方输入框内提交您的友情链接申请，博主会在一定时间内审核

***

#### 本站信息
> 名称 \`仙娥小站\`
> 
> 链接 \`https://www.sheaneh.com/\`
> 
> 头像 \`https://www.sheaneh.com/avatar.png\`
> 
> 描述 \`花遇时光，莫逆于心\`

`;
		const noticeTextUyg = `#### ئالدى بىلەن ئوقۇش

- قانۇن-بەلگىلىمىگە خىلاپ بارلىق تور بېكەتلىرى، مەھسۇلات سېتىش، ئېلان ئىتتىپاقى قاتارلىق تۈردىكى تور بېكەتلىرى مەنئى قىلىنىدۇ

- سىزنىڭ تور بېكىتى چوقۇم بىلوگ تۈرىدىكى بېكەت، راۋان زىيارەت قىلىش، مۇقىم يېڭىلاش، SSL بىخەتەرلىك گۇۋاھنامىسى ئىگە

- ماڭا سىزنىڭ تور بېكىتى قوشۇش، ئۇزېلىمىزنىڭ دوستلۇق ئۇلانمىسى

- سىزنىڭ ئىلتىماسىڭىزنى تەكشۈرۈش كېرەك

***

#### ئۇزېلىمىزنىڭ ئۇچۇر
> تور بېكىتى نامى \` شىانه \`
> 
> تور ئادرېسى \`https://sheaneh.com\`
> 
> باش سۈرىتى \`https://sheaneh.com/avatar.png\`
> 
> قىسقىچە تونۇشتۇرۇش \`بىزنىڭ ئۇچرىشىشىمىز خاتىرىلەشكە ئەرزىيدۇ\`

`;
		const noticeText: Ref<string> = ref(noticeTextZh);
		watch(
			() => root.$i18n.locale,
			newValue => (timer = setTimeout(() => (noticeText.value = newValue === 'uyg' ? noticeTextUyg : noticeTextZh), 1)),
			{ immediate: true }
		);
		onBeforeUnmount(() => clearTimeout(timer));
		//临时允许复制拷贝
		onMounted(() => {
			(window as any).__allowCopy = true;
		});
		onBeforeUnmount(() => {
			(window as any).__allowCopy = false;
		});
		//功能块：实现申请链接的表单提交
		const registerFormData: Ref<RegisterFormData> = ref({
			title: '',
			url: '',
			avatar: '',
			description: '',
		});
		const handleChange = (key: string) => (value: string | undefined) => (registerFormData.value[key] = '' + value);
		const formInputData: Ref<any[]> = ref([
			{
				key: 'title',
				title: t('partRegisterLink.form.title'), //'名称',
				placeholder: t('partRegisterLink.form.titlePlaceholder'), //'请输入您的博客昵称或网站名称',
				isMust: true,
				onChange: handleChange('title'),
			},
			{
				key: 'url',
				title: t('partRegisterLink.form.url'), //'链接',
				placeholder: t('partRegisterLink.form.urlPlaceholder'), //'请输入您的网站链接',
				isMust: true,
				onChange: handleChange('url'),
			},
			{
				key: 'avatar',
				title: t('partRegisterLink.form.avatar'), //'头像',
				placeholder: t('partRegisterLink.form.avatarPlaceholder'), //'输入头像或网站图标的URL地址',
				isMust: true,
				onChange: handleChange('avatar'),
			},
			{
				key: 'description',
				title: t('partRegisterLink.form.description'), //'描述',
				placeholder: t('partRegisterLink.form.descriptionPlaceholder'), //'一句话描述',
				isMust: true,
				onChange: handleChange('description'),
			},
		]);
		const submitRegisterForm = () => {
			//console.log(registerFormData.value);
			const jsonStr = JSON.stringify({ ...registerFormData.value } || {});
			//console.log(jsonStr);
			req('LINK_FRIENDS_ADD', {
				data: jsonStr,
				validate_hash: (sha1 as any).hex(jsonStr),
			})
				.then((data: any) => {
					if (data.add_result) {
						$message.success('提交成功，请等待博主的审核');
						isSubmit.value = true;
					} else {
						throw '未成功提交';
					}
				})
				.catch(error => {
					$message.error('' + error);
				});
		};
		const isSubmit: Ref<boolean> = ref(false);
		return {
			noticeText,
			registerFormData,
			submitRegisterForm,
			handleChange,
			formInputData,
			isSubmit,
			t,
		};
	},
});
