import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import { Empty } from '@/components/UI';
import FcItem from './fc-item';
import { req } from '@/api';

enum favoritesType {
	'dir',
	'file',
}

interface O {
	readonly onlyid: string | number;
	readonly isDir: boolean;
}
interface Dir extends O {
	dirname: string;
	fileCount?: number;
	children: (Dir | File)[];
}
interface File extends O {
	title: string;
	description?: string;
	url: string;
}
type Favorites = (Dir | File)[];
interface WebItem {
	isDisabled?: boolean;
	url: string;
	icon: string | null;
	title: string;
}
interface MyOtherWeb extends WebItem {
	description: string;
	createInfo: string;
	compatInfo: string[];
}
interface FriendsOtherWeb extends WebItem {
	authorOnlyid: string;
	author: string;
	authorUrl: string;
}

export default defineComponent({
	components: { Card, FcItem, Empty },
	setup(props, { root }: any) {
		const { $message } = root;
		const t = root.$t.bind(root);
		const isError: Ref<boolean> = ref(false);
		const errorMsg: Ref<string> = ref('');
		const myOtherWeb: Ref<MyOtherWeb[]> = ref([]);
		const friendsOtherWeb: Ref<FriendsOtherWeb[]> = ref([]);
		const deniedClick = (event: Event) => {
			$message.error('网站已关闭无法打开');
			event.preventDefault();
		};
		const favorites: Ref<Favorites> = ref([]);
		const init = () => {
			req('LINK_FAVORITE_ALL')
				.then((data: any) => {
					//console.log(data);
					myOtherWeb.value = data.myOtherWeb || [];
					friendsOtherWeb.value = data.friendsOtherWeb || [];
					favorites.value = data.favorites || [];
				})
				.catch(error => {
					isError.value = true;
					errorMsg.value = '' + error;
					$message.error(error);
				});
		};
		onMounted(() => {
			init();
		});
		return {
			myOtherWeb,
			friendsOtherWeb,
			deniedClick,
			favorites,
			isError,
			errorMsg,
			t,
		};
	},
});
