









































import { defineComponent, ref, Ref } from '@vue/composition-api';

interface O {
    readonly onlyid: string | number;
    readonly isDir: boolean;
}
interface Dir extends O {
    dirname: string;
    fileCount?: number;
    children: (Dir | File)[];
}
interface File extends O {
    title: string;
    description?: string;
    url: string;
}

export default defineComponent({
    name: 'fcItem',
    props: {
        item: {
            type: Object,
        },
    },
    setup() {
        const isOpen: Ref<boolean> = ref(false);
        const handleOpen = (event: Event, item: File | Dir) => {
            if (item.isDir) {
                isOpen.value = !isOpen.value;
                event.preventDefault();
            }
        };
        return { isOpen, handleOpen };
    },
});
