import { defineComponent, ref, Ref } from '@vue/composition-api';

import Card from '@/components/Card';
import Friends from '@/components/Article/Friends';

enum sex {
	未知 = -1,
	男 = 0,
	女 = 1,
}

interface MyAdore {
	onlyid: string;
	title: string;
	sex: sex;
	description: string;
}

export default defineComponent({
	components: { Card, Friends },
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		const myAdore: Ref<MyAdore[]> = ref([
			{
				onlyid: 'usuuu.com',
				title: '悠悠',
				sex: 1,
				description: '青青子矜，悠悠我心。一个集美貌、智慧于一体的女神，掌握着各种全网最牛逼的尖端技术，前端后端通吃，精通各类高端程序框架，拥有常人无法触及的效率。企业级模板设计、程序订制。',
			},
			{
				onlyid: 'toubiec.cn',
				title: '晓晴',
				sex: 1,
				description: '做自己的女王，高材生，二次元大佬，为很多动漫、游戏做过汉化包。作为网络安全员，提交过各种企事业单位网站的安全漏洞，用实力碾压一切。',
			},
			{
				onlyid: 'rz.sb',
				title: '若志',
				sex: 0,
				description: '网页创意的开拓者，为 Typecho 程序设计过诸多主题，并亲自编写过文档。',
			},
		]);
		const openLink = (onlyid: string) => {
			const openWindow = window.open(`/redirect/${onlyid}`, `foo-${onlyid}`, 'noopener=yes,noreferrer=yes');
			if (openWindow !== null) {
				//(openWindow as any).alert('123');
			}
		};
		return { myAdore, openLink, t };
	},
});
